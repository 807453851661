import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index';
import Login from '@/views/login/login';
import Home from '@/views/home/home';
import Main from '@/views/layouts/main/main';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: "Main",
    component: Main,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/admin_user',
        component: () => import("@/views/systemManager/admin_user/admin_user.vue"),
      },
      {
        path: '/admin_role',
        component: () => import("@/views/systemManager/admin_role/admin_role.vue"),
      },
      {
        path: '/admin_action',
        component: () => import("@/views/systemManager/admin_action/admin_action.vue"),
      },
      {
        path: '/admin_opt_log',
        component: () => import("@/views/systemManager/admin_opt_log/admin_opt_log.vue"),
      },
      {
        path: '/member',
        component: () => import("@/views/memberManager/member/member.vue"),
      },
      {
        path: '/product_cate',
        component: () => import("@/views/productManager/product_cate/product_cate.vue"),
      },
      {
        path: '/product',
        component: () => import("@/views/productManager/product/product.vue"),
      },
      {
        path: '/help',
        component: () => import("@/views/webManager/help.vue"),
      },
      {
        path: '/about',
        component: () => import("@/views/webManager/about.vue"),
      },
      {
        path: '/buy_note',
        component: () => import("@/views/webManager/buy_note.vue"),
      },
      {
        path: '/product_return',
        component: () => import("@/views/webManager/product_return.vue"),
      },
      {
        path: '/privacy_policy',
        component: () => import("@/views/webManager/privacy_policy.vue"),
      },
      {
        path: '/points_rule',
        component: () => import("@/views/webManager/points_rule.vue"),
      },
      {
        path: '/carousel',
        component: () => import("@/views/webManager/carousel.vue"),
      },
      {
        path: '/order',
        component: () => import("@/views/orderManager/order/order.vue"),
      },
      {
        path: '/points_detail',
        component: () => import("@/views/pointsManager/points_detail/points_detail.vue"),
      },
      {
        path: '/product_report',
        component: () => import("@/views/reportManager/product_report/product_report.vue"),
      },
      {
        path: '/points_report',
        component: () => import("@/views/reportManager/points_report/points_report.vue"),
      },
      {
        path: '/product_sale',
        component: () => import("@/views/productManager/product_sale/product_sale.vue"),
      },
      {
        path: '/activity',
        component: () => import("@/views/activityManager/activity/activity.vue"),
      },
      {
        path: '/activity_order',
        component: () => import("@/views/activityManager/activity_order/activity_order.vue"),
      },
      {
        path: '/activity_report',
        component: () => import("@/views/activityManager/activity_report/activity_report.vue"),
      },
      {
        path: '/under_maintenance',
        component: () => import("@/views/errors/503/503.vue"),
      },
      {
        path: '/:pathMatch(.*)*',
        component: () => import("@/views/errors/404/404.vue"),
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if( to.meta.auth ) {
    var ok = store.state.user.isLogin;
    if( ok ) {
      next();
    } else {
      next("/login");
    }
  }
  next();
});

export default router
