<template>
    <div class="top_menu">
        <div class="rightDiv">
            <div class="userInfo">
                <a-space>
                    <a-dropdown>
                        <u style="cursor: pointer;">
                            {{ $t('top_menu.login_account') }}&nbsp;:&nbsp;
                            <span class="value">{{ states.nickname }}</span>
                        </u>
                        <template #overlay>
                            <a-menu>
                                <a-menu-item @click="openChangePassword">
                                    <span>{{ $t('top_menu.change password') }}</span>
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                </a-space>
            </div>
            <a-button type="text" @click="onClickLogout" class="logoutBtn">{{$t('top_menu.logout')}}</a-button>
        </div>
    </div>

    <a-modal v-model:visible="passwordModelStatus" :title="$t('top_menu.change password')" @ok="onChangePassword" @cancel="onCloseChangePassword" :confirm-loading="loading">
            <a-form :model="formData" layout="vertical">
                <a-row :gutter="16">
                    <a-col span="24">
                        <a-form-item :label="$t('admin_user.password')">
                            <a-input-password v-model:value="formData.password" placeholder="長度為6-12" />
                        </a-form-item>
                    </a-col>
                    <a-col span="24">
                        <a-form-item :label="$t('admin_user.password_check')">
                            <a-input-password v-model:value="formData.password_check" />
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
        </a-modal>
</template>

<script>
import './top_menu.scss';
import { reactive, computed, ref } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { message } from 'ant-design-vue';
import { authChangePassword } from '@/api-core/api-core';
    
export default {
    name: 'TopMenu',
    components: {
        
    },
    setup() {
        const store = useStore();
        const { t } = useI18n();
        const router = useRouter();
        
        const states = reactive({
            up_user_name : computed(() => store.state.user.up_user_name),
            nickname : computed(() => store.state.user.nickname),
            user_identity: computed(()=> store.state.user.identity),
        });

        // const onClickLogo = () => {
        //     window.location.reload();
        // };

        const onClickLogout = () => {
            store.dispatch("user/logout")
            .then(() => {
                message.success(t("top_menu.logout success"));
            })
            .finally(() => {
                router.push({ path: "/login" });
            });
        };

        const loading = ref(false);
        const formData = reactive({
            password: "",
            password_check: "",
        });
        const formDataObj = reactive({
            password: "",
            password_check: "",
        });
        const passwordModelStatus = ref(false);
        const openChangePassword = () => {
            passwordModelStatus.value = true;
        };

        const onChangePassword = () => {
            loading.value = true;
            authChangePassword(formData).then(res => {
                message.success(t("form.edit success"));
                store.dispatch("user/logout")
                .then(() => {
                    message.success(t("top_menu.please login again"));
                })
                .finally(() => {
                    router.push({ path: "/login" });
                });
            }).finally(() => {
                Object.assign(formData, formDataObj);
                loading.value = false;
                passwordModelStatus.value = false;
            })
        };
        const onCloseChangePassword = () => {
            passwordModelStatus.value = false;
        };

        return {
            onClickLogout, states, openChangePassword, passwordModelStatus, 
            onChangePassword, onCloseChangePassword, formData, loading, 
        }
    }
};
</script>