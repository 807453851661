import axios from 'axios';
import store from '@/store/index';
import router from '@/router/index';
import i18n from '@/locale/index';
import { message } from 'ant-design-vue';

const api = axios.create({
    baseURL: "/api/cms",
});
api.defaults.withCredentials = true;
api.interceptors.request.use( config => {
    config.headers.Authorization = `Bearer ${ store.state.user.token }`;
    return config;
}, err => {
    Promise.reject(err);
});

api.interceptors.response.use( res => {
    if( res.status == 200 ) {
        var res_data = res.data;
        if( !res_data.hasOwnProperty("code") ) {
            return Promise.reject(res);
        }
        var code = res_data.code;
        if( code != 20000 ) {
            // message.error(i18n.global.t('error.'+res.data.msg));
            var msg = res.data.msg;
            if(res.data.data != null && Array.isArray(res.data.data)) {
                var arr = res.data.data;
                if(arr.length > 0) {
                    msg += ", " + i18n.global.t('error.'+arr[0]);
                } 
            }
            message.error(msg);
            if( code == 20401 ) {
                router.push({ path: '/login' });
            }
            return Promise.reject(res);
        }
        return Promise.resolve(res.data);
    }

    return Promise.reject(res);
}, err => {
    if( err.response.status == 401 ) {
        // message.info(i18n.global.t('error.'+err.response.data.msg));
        message.info(err.response.data.msg);
        router.push({ path: '/login' });
    } else if( err.response.status == 503 ) {
        router.push({ path: '/under_maintenance' });
    } else {
        console.log(err);
        message.error(i18n.global.t('error.something wrong'));
    }
    return Promise.reject(err);
});

export const authLogin = (params) => api.post("/auth_login", params);
export const authLogout = () => api.get("/auth/logout");
export const getMenu = () => api.get("/admin_menu");
export const authChangePassword = (params) => api.post("/auth/change_password", params);

export const adminUserSearchData = (params) => api.get("/admin_user", {params});
export const adminUser = (params) => api.get("/admin_user/list", {params});
export const adminUserAdd = (params) => api.post("/admin_user/add", params);
export const adminUserEdit = (params) => api.post("/admin_user/edit", params);
export const adminUserDelete = (params) => api.post("/admin_user/delete", params);
export const adminUserChangePassword = (params) => api.post("/admin_user/change_password", params);

export const adminRole = (params) => api.get("/admin_role/list", {params});
export const adminRoleAdd = (params) => api.post("/admin_role/add", params);
export const adminRoleEdit = (params) => api.post("/admin_role/edit", params);
export const adminRoleDelete = (params) => api.post("/admin_role/delete", params);
export const adminRoleGetAction = (params) => api.post("/admin_role/get_action", params);

export const adminAction = (params) => api.get("/admin_action/list", {params});
export const adminActionAdd = (params) => api.post("/admin_action/add", params);
export const adminActionEdit = (params) => api.post("/admin_action/edit", params);
export const adminActionDelete = (params) => api.post("/admin_action/delete", params);

export const adminOptLog = (params) => api.get("/admin_opt_log/list", {params});

export const memberSearchData = (params) => api.get("/member", {params});
export const member = (params) => api.get("/member/list", {params});
export const memberAdd = (params) => api.post("/member/add", params);
export const memberEdit = (params) => api.post("/member/edit", params);
export const memberDelete = (params) => api.post("/member/delete", params);

export const memberPointsDetail = (params) => api.get("/member_points_detail/list", {params});
export const memberPointsDetailAdd = (params) => api.post("/member_points_detail/add", params);
export const memberPointsDetailDelete = (params) => api.post("/member_points_detail/delete", params);

export const productCateSearchData = (params) => api.get("/product_cate", {params});
export const productCate = (params) => api.get("/product_cate/list", {params});
export const productCateAdd = (params) => api.post("/product_cate/add", params);
export const productCateEdit = (params) => api.post("/product_cate/edit", params);
export const productCateDelete = (params) => api.post("/product_cate/delete", params);

export const productSearchData = (params) => api.get("/product", {params});
export const product = (params) => api.get("/product/list", {params});
export const productAdd = (params) => api.post("/product/add", params);
export const productEdit = (params) => api.post("/product/edit", params);
export const productDelete = (params) => api.post("/product/delete", params);
export const productUpload = (params) => api.post("/product/upload", params);

export const productSaleSearchData = (params) => api.get("/product_sale", {params});
export const productSale = (params) => api.get("/product_sale/list", {params});
export const productSaleAdd = (params) => api.post("/product_sale/add", params);
export const productSaleEdit = (params) => api.post("/product_sale/edit", params);
export const productSaleDelete = (params) => api.post("/product_sale/delete", params);

export const about = (params) => api.get("/about", {params});
export const aboutEdit = (params) => api.post("/about/edit", params);
export const help = (params) => api.get("/help", {params});
export const helpEdit = (params) => api.post("/help/edit", params);
export const buyNote = (params) => api.get("/buy_note", {params});
export const buyNoteEdit = (params) => api.post("/buy_note/edit", params);
export const privacyPolicy = (params) => api.get("/privacy_policy", {params});
export const privacyPolicyEdit = (params) => api.post("/privacy_policy/edit", params);
export const productReturn = (params) => api.get("/product_return", {params});
export const productReturnEdit = (params) => api.post("/product_return/edit", params);
export const pointsRule = (params) => api.get("/points_rule", {params});
export const pointsRuleEdit = (params) => api.post("/points_rule/edit", params);
export const carousel = (params) => api.get("/carousel/list", {params});
export const carouselUploadMob = (params) => api.post("/carousel/upload/mobile", params);
export const carouselUploadPC = (params) => api.post("/carousel/upload/pc", params);
export const carouselDelete = (params) => api.post("/carousel/delete", params);
export const carouselEdit = (params) => api.post("/carousel/edit", params);
export const webSettingUpload = (params) => api.post("/web_setting/upload", params);

export const order = (params) => api.get("/order/list", {params});
export const orderEdit = (params) => api.post("/order/edit", params);
export const orderCancel = (params) => api.post("/order/cancel", params);
export const orderDeal = (params) => api.post("/order/deal", params);
export const orderFinish = (params) => api.post("/order/finish", params);

export const pointsDetail = (params) => api.get("/points_detail/list", {params});
export const pointsDetailChange = (params) => api.post("/points_detail/change", params);
export const pointsDetailImport = (params) => api.post("/points_detail/import", params);

export const productReportSearchData = () => api.get("/product_report");
export const productReport = (params) => api.get("/product_report/list", {params});

export const pointsReport = (params) => api.get("/points_report/list", {params});

export const activity = (params) => api.get("/activity/list", {params});
export const activityAdd = (params) => api.post("/activity/add", params);
export const activityEdit = (params) => api.post("/activity/edit", params);
export const activityCancel = (params) => api.post("/activity/cancel", params);
export const activityProductCate = (params) => api.get('/activity/product_cate/list', {params});
export const activityProductCateAdd = (params) => api.post('/activity/product_cate/add', params);
export const activityProductCateEdit = (params) => api.post('/activity/product_cate/edit', params);
export const activityProductCateDelete = (params) => api.post('/activity/product_cate/delete', params);
export const activityProduct = (params) => api.get('/activity/product/list', {params});
export const activityProductAdd = (params) => api.post('/activity/product/add', params);
export const activityProductEdit = (params) => api.post('/activity/product/edit', params);
export const activityProductDelete = (params) => api.post('/activity/product/delete', params);
export const activityProductUpload = (params, id) => api.post(`/activity/product/upload/${id}`, params);

export const activityOrder = (params) => api.get('/activity_order/list', {params});
export const activityOrderEdit = (params) => api.post('/activity_order/edit', params);

export const activityReportSearchData = () => api.get("/activity_report");
export const activityReport = (params) => api.get("/activity_report/list", {params});