<template>
    <div class="login">
            <a-layout class="login-layout">
                <a-layout-header class="login-layout-header">
                    <span class="title">{{$t('login.title')}}</span>
                </a-layout-header>
                <a-layout-content class="login-layout-content">
                    <div class="login-layout-content-formdiv">
                        <h2>{{ $t('login.title') }}</h2>
                        <a-form class="login-layout-content-formdiv-form" :model="formData" autocomplete="off" @finish="onFormFinish" @finishFailed="onFormFinishFaild">
                            <a-form-item :label="$t('login.username')" name="username" :rules="[{ required: true, message: $t('login.please enter username') }]">
                                <a-input size="large" v-model:value="formData.username" />
                            </a-form-item>
                            <a-form-item :label="$t('login.password')" name="password" :rules="[{ required: true, message: $t('login.please enter password') }]">
                                <a-input-password size="large" v-model:value="formData.password" />
                            </a-form-item>
                            <a-form-item>
                                <a-button type="primary" html-type="submit">{{$t('login.login')}}</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                </a-layout-content>
            </a-layout>
    </div>
</template>

<script>
import './login.scss';
import { reactive } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { message } from 'ant-design-vue';
import { useRouter, useRoute } from 'vue-router';

export default {
    name: 'Login',
    components: {
         
    },
    setup() {
        const { t } = useI18n();
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const formData = reactive({
            username: "",
            password: "",
        });
        
        const onFormFinish = (values) => {
            store.dispatch("user/login", values)
            .then(() => {
                message.success(t('login.login success'));
                router.push({ path: '/' });
            });
        };

        const onFormFinishFaild = (error) => {
            console.log(error);
        };
        
        return {
            formData, onFormFinish, onFormFinishFaild, 
        }
    }
};
</script>