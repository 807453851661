<template>
  <div class="app">
    <router-view/>
  </div>
</template>

<script>
  import './App.scss';

  export default {
    name: 'App',
  }
</script>



<!-- <template>
  <div>
    <draggable
      :list="draggableList"
    >
      <template #item="{ index, element }">
        <p >
          {{ index + 1 }} . {{ element }}
        </p>
      </template>
    </draggable>
  </div>
</template>
<script>
import { ref } from "vue";
import draggable from "vuedraggable";
export default {
  name: "HomeView",
  components: {
    draggable,
  },
  setup() {
    const draggableList = ref([
      "AAAAAAAAA",
      "BBBBBBBBBBB",
      "CCCCCCCCCCC",
      "DDDDDDDDD",
      "EEEEEEEEEEE",
      "FFFFFFFFFFFF",
    ]);
    return {
      draggableList,

    };
  },
};
</script> -->
