import { authLogin, authLogout } from '@/api-core/api-core';

export default {
    namespaced: true,
    state: {
        isLogin: false,
        user_id: null,
        username: "",
        nickname: "",
        role_id: null,
        token: "",
    },
    mutations: {
        setUser(state, data) {
            state.isLogin = true;
            state.user_id = data.id || null;
            state.username = data.username || "";
            state.nickname = data.nickname || "";
            state.role_id = data.role_id || null;
            state.token = data.token || "";
        },
        removeUser(state) {
            state.isLogin = false;
            state.user_id = null;
            state.username = "";
            state.nickname = "";
            state.role_id = null;
            state.token = "";
        }
    },
    actions: {
        login({commit}, values) {
            return authLogin(values).then(res => {
                if( res.code == 20000 ) {
                    commit("setUser", res.data);
                    return Promise.resolve(res.data);
                } else {
                    commit("removeUser");
                    return Promise.reject(res.msg);
                }
            }).catch(err => {
                commit("removeUser");
                return Promise.reject(err);
            })
        },
        logout({commit}) {
            authLogout().finally(() => {
                commit("removeUser");
            });
        }
    },
    getters: {
        
    }
}